import React from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string';
import classnames from 'classnames';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Page from '../components/page';
import data from '../content/pages/contact.yml';
import settings from '../content/admin/settings.json';

const phoneUri = `tel:1${settings.phone.replace(/[^0-9]+/g, '')}`;
const FORM_NAME = 'Contact Form';

const IDLE = 'idle';
const SENDING = 'sending';
const SUCCESS = 'success';
const ERROR = 'error';

const Contact = ({ location }) => {
  const [status, setStatus] = React.useState(IDLE);
  const formRef = React.useRef();

  const handleFormSubmit = async e => {
    e.preventDefault();
    let formElements = e.target.elements;
    let formData = {};

    for (let el of formElements) {
      if (!el.name) continue;
      formData[el.name] = el.value;
    }

    setStatus(SENDING);

    fetch(location.pathname, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: queryString.stringify(formData),
    })
      .then(() => {
        formRef.current.reset();
        setStatus(SUCCESS);
      })
      .catch(() => {
        formRef.current.reset();
        setStatus(ERROR);
      });
  };

  const btnState = {
    [SENDING]: {
      text: 'Sending...',
      class: 'is-primary',
    },
    [IDLE]: {
      text: 'Send',
      class: 'is-primary',
    },
    [SUCCESS]: {
      text: 'Success!',
      class: 'is-success',
    },
    [ERROR]: {
      text: 'Error',
      class: 'is-error',
    },
  }[status];

  return (
    <Layout>
      <SEO title="Contact"></SEO>
      <Page>
        <Page.Header title={data.title} subtitle={data.subtitle} />
        <Page.Content>
          <div className="columns">
            <div className="column is-half is-offset-one-quarter">
              <div className="content">
                <h3>{settings.title}</h3>
                <p>
                  <strong>Email: </strong>
                  <a href={`mailto:${settings.email}`}>{settings.email}</a>
                  <br />
                  <strong>Phone: </strong>
                  <a href={phoneUri}>{settings.phone}</a>
                  <br />
                  <strong>Address:</strong>
                  <br />
                  {settings.address_line_1} <br />
                  {settings.address_line_2}
                </p>
                <hr className="is-hidden-mobile" />
                <p>
                  For any questions or inquiries please fill out the form below.
                  We look forward to hearing from you.
                </p>
                {(status === SENDING || status === IDLE) && (
                  <form
                    ref={formRef}
                    onSubmit={handleFormSubmit}
                    name={FORM_NAME}
                    method="POST"
                    data-netlify="true"
                  >
                    <input type="hidden" name="form-name" value={FORM_NAME} />
                    <div className="field">
                      <label className="label" htmlFor="name">
                        Name:
                      </label>
                      <p className="control">
                        <input
                          className="input"
                          type="text"
                          name="name"
                          placeholder="Full Name"
                          required
                        />
                      </p>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor="company">
                        Company name:
                      </label>
                      <p className="control">
                        <input
                          className="input"
                          type="text"
                          name="company"
                          placeholder="Company, Inc."
                          required
                        />
                      </p>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor="email">
                        Email:
                      </label>
                      <p className="control">
                        <input
                          className="input"
                          type="email"
                          name="email"
                          placeholder="address@example.com"
                          required
                        />
                      </p>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor="phone">
                        Phone number:
                      </label>
                      <p className="control">
                        <input
                          className="input"
                          type="text"
                          name="phone"
                          placeholder="123-456-7890"
                        />
                      </p>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor="message">
                        Message:
                      </label>
                      <p className="control">
                        <textarea
                          className="textarea"
                          name="message"
                          placeholder="Type your message here"
                          required
                        />
                      </p>
                    </div>
                    <div className="field">
                      <p className="control">
                        <input
                          value={btnState.text}
                          type="submit"
                          disabled={status === SENDING}
                          className={classnames('button', btnState.class)}
                        />
                      </p>
                    </div>
                  </form>
                )}

                {status !== SENDING && status !== IDLE && (
                  <>
                    <div className={classnames('notification', btnState.class)}>
                      <p>
                        {status === SUCCESS &&
                          'Your message was sent successfully! We will be in touch shortly.'}
                        {status === ERROR &&
                          'An error occured while sending your message. Please give us a call at ' +
                            settings.phone}
                      </p>
                    </div>
                    <p className="has-text-centered">
                      <Link className="button is-primary" to="/">
                        Return Home
                      </Link>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </Page.Content>
      </Page>
    </Layout>
  );
};

export default Contact;
